import React, { useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import { Box } from "@mui/material";

import FloatingPlotTable from "./FloatingPlotTable";
import ZoomToPolygon from "./ZoomToPolygon";
import PlotData from "./PlotData";
import { t } from "i18next";
const PolygonContainer = ({
  farms,
  latitude = 25.0888596,
  longitude = 55.1482862,
  beneficiary,
}) => {
  const mapRef = useRef();

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [farmToZoom, setFarmToZoom] = useState(null);

  const handlePolygonClick = (farm) => {
    setSelectedFarm(farm);
  };

  const handleFarmToView = (plotId, mFarm) => {
    const farm = mFarm.find((farm) => farm?.id === plotId);
    setFarmToZoom(farm);
  };

  const map_center_lat =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LAT || latitude;
  const map_center_lon =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LON || longitude;
  const map_zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL || 15;

  const geoLevels = [
    { level: 1, value: beneficiary?.geo_level_1 },
    { level: 2, value: beneficiary?.geo_level_2 },
    { level: 3, value: beneficiary?.geo_level_3 },
    { level: 4, value: beneficiary?.geo_level_4 },
    { level: 5, value: beneficiary?.geo_level_5 },
    { level: 6, value: beneficiary?.geo_level_6 },
  ];

  return (
    <Box position="relative">
      <MapContainer
        center={[map_center_lat, map_center_lon]}
        zoom={map_zoom}
        style={{ height: "80vh" }}
        whenCreated={(map) => (mapRef.current = map)}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="OpenStreetMap"
        />
        {beneficiary && (
          <Marker position={[latitude, longitude]}>
            <Popup>
              {t("beneficiaries.table.gps")}: <strong>{latitude}</strong>,{" "}
              <strong>{longitude}</strong>
              <br />
              {t("beneficiaries.table.package_names")}:{" "}
              <strong>
                {beneficiary?.packages.map((pkg) => pkg.name).join(", ")}
              </strong>
              <br />
              {t("beneficiaries.table.distribution_point")}:{" "}
              <strong>{beneficiary?.distribution_point?.title}</strong>
              <br />
              {geoLevels.map(
                (geo) =>
                  geo.value && (
                    <div key={geo.level}>
                      {t("beneficiaries.table.geo_level")} {geo.level}:{" "}
                      <strong>{geo.value}</strong>
                    </div>
                  )
              )}
            </Popup>
          </Marker>
        )}
        {farmToZoom && <ZoomToPolygon farm={farmToZoom} />}
        {farms.length > 0 ? (
          farms.map((farm) => (
            <Polygon
              key={farm.id}
              positions={farm?.plot_polygon_geometry?.coordinates}
              color="blue"
              eventHandlers={{
                click: () => handlePolygonClick(farm),
              }}
            >
              {selectedFarm === farm && (
                <PlotData selectedFarm={selectedFarm} />
              )}
            </Polygon>
          ))
        ) : (
          <Polygon
            positions={farms?.plot_polygon_geometry?.coordinates}
            color="blue"
            eventHandlers={{
              click: () => handlePolygonClick(farms),
            }}
          >
            {
              selectedFarm === farms && <p>Well</p>
              //  <PlotData selectedFarm={selectedFarm} />
            }
          </Polygon>
        )}
      </MapContainer>
      <FloatingPlotTable farmToView={handleFarmToView} />
    </Box>
  );
};

export default PolygonContainer;
