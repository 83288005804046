import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BiIcons from "react-icons/bi";
import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";
import * as TbIcons from "react-icons/tb";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

export const SidebarData = [
  {
    title: "Home",
    to: "/",
    icon: <AiIcons.AiFillHome />,
    allowed: "dashboard-list",
  },
  {
    title: "Projects",
    to: "/projects",
    icon: <AiIcons.AiOutlineProject />,
    subNav: [
      {
        title: "Projects",
        to: "/projects",
        icon: <AiIcons.AiOutlineProject />,
        allowed: "project-list",
      },
      {
        title: "Donors",
        to: "/projects/donors",
        icon: <IoIcons.IoIosPeople />,
        allowed: "donor-list",
      },
      {
        title: "Purchase Orders",
        to: "/projects/purchase-orders",
        icon: <BiIcons.BiPurchaseTag />,
        allowed: "purchase-order-list",
      },
    ],
    allowed: "project-list",
  },
  {
    title: "Interventions",
    to: "/interventions",
    icon: <FiIcons.FiActivity />,
    subNav: [
      {
        title: "Interventions",
        to: "/interventions",
        icon: <FiIcons.FiActivity />,
        allowed: "intervention-list",
      },
      {
        title: "Entitlement Types",
        to: "/interventions/entitlement-types",
        icon: <MdIcons.MdOutlineMergeType />,
        allowed: "entitlement-type-list",
      },
      {
        title: "Eligibility Criteria",
        to: "/interventions/entitlement-criteria",
        icon: <BiIcons.BiAbacus />,
        // #TODO: Check which permission
        allowed: "entitlement-type-list",
      },
      {
        title: "Entitlements",
        to: "/interventions/entitlements",
        icon: <AiIcons.AiFillCreditCard />,
        allowed: "entitlement-list",
      },
      {
        title: "Packages",
        to: "/interventions/packages",
        icon: <FaIcons.FaBriefcase />,
        allowed: "package-list",
      },
      {
        title: "Seasons",
        to: "/interventions/seasons",
        icon: <IoIcons.IoMdPartlySunny />,
        allowed: "season-list",
      },
      {
        title: "Topups",
        to: "/interventions/topups",
        icon: <AiIcons.AiFillFolderAdd />,
        allowed: "topup-list",
      },
    ],
    allowed: "intervention-list",
  },
  {
    title: "Distribution Points",
    to: "/distribution-points",
    icon: <BiIcons.BiMapPin />,
    allowed: "distribution-point-list",
  },
  {
    title: "Data Manager",
    to: "/data",
    icon: <DataThresholdingOutlinedIcon />,
    subNav: [
      {
        title: "Kobo",
        to: "data/kobo",
        icon: <FaIcons.FaWpforms />,
        allowed: "koboforms-list",
      },
      // {
      //   title: "Dynamic Forms",
      //   to: "data/dynamic/forms",
      //   icon: <DynamicFormOutlinedIcon />,
      // },
    ],
    allowed: "koboforms-list",
  },
  {
    title: "Beneficiaries",
    to: "/beneficiaries",
    icon: <IoIcons.IoIosPeople />,
    subNav: [
      {
        title: "Beneficiaries",
        to: "/beneficiaries",
        icon: <IoIcons.IoIosPeople />,
        allowed: "beneficiary-list",
      },
      {
        title: "Biometric Deduplication",
        to: "/beneficiaries/biometric-deduplication",
        icon: <FaIcons.FaPeopleArrows />,
        allowed: "beneficiary-list",
      },
      {
        title: "Non Biometric Deduplication",
        to: "/beneficiaries/non-biometric-deduplication",
        icon: <FaIcons.FaPeopleArrows />,
        allowed: "beneficiary-list",
      },
      {
        title: "Farms",
        to: "/beneficiaries/farms",
        icon: <LandscapeOutlinedIcon />,
        allowed: "beneficiary-list",
      },
      {
        title: "Cards",
        to: "/beneficiaries/cards",
        icon: <CreditCardOutlinedIcon />,
        allowed: "card-list",
      },
    ],
    allowed: "beneficiary-list",
  },
  {
    title: "Payments",
    to: "payments",
    icon: <PaymentsOutlinedIcon />,
    subNav: [
      {
        title: "Cash",
        to: "payments/cash",
        icon: <MdIcons.MdOutlinePayments />,
        tooltip: "View all Cash payments",
        dataCount: 0,
        allowed: "cash-list",
      },
      {
        title: "Copay Data",
        to: "payments/copay",
        icon: <AiIcons.AiOutlineMoneyCollect />,
        tooltip: "View all Copay Data payments",
        dataCount: 0,
        allowed: "copay-list",
      },
      {
        title: "E-Voucher",
        to: "payments/evoucher",
        icon: <FaIcons.FaMoneyBillWaveAlt />,
        tooltip: "View all electronic voucher payments",
        dataCount: 0,
        allowed: "redemption-list",
      },
      {
        title: "In-Kind",
        to: "payments/inkind",
        icon: <FaIcons.FaMoneyCheckAlt />,
        tooltip: "View all In-Kind payments",
        dataCount: 0,
        allowed: "redemption-list",
      },
    ],
    allowed: "payment-list",
  },
  {
    title: "Reports",
    to: "reports",
    icon: <TbIcons.TbReportAnalytics />,
    subNav: [
      {
        title: "Payments Report",
        to: "reports/cash-report",
        icon: <MdIcons.MdOutlinePayments />,
        tooltip: "View all Cash payments",
        dataCount: 0,
        allowed: "cash-list",
      },
      {
        title: "Reports",
        to: "reports",
        icon: <TbIcons.TbReportMoney />,
        tooltip: "E-Voucher Liquidation Report",
        allowed: "report-evoucher-liquidation",
      },
      {
        title: "E-Voucher Report",
        to: "reports/evoucher",
        icon: <TbIcons.TbReportMoney />,
        tooltip: "E-Voucher Liquidation Report",
        allowed: "report-evoucher-liquidation",
      },
    ],
    allowed: "report-evoucher-liquidation",
  },
  {
    title: "User Management",
    to: "user_settings",
    icon: <FaIcons.FaUsersCog />,
    subNav: [
      {
        title: "Roles",
        to: "/roles",
        icon: <FaIcons.FaUserCog />,
        allowed: "role-list",
      },
      {
        title: "Users",
        to: "/users",
        icon: <FaIcons.FaUsers />,
        allowed: "user-list",
      },
      {
        title: "Service Providers",
        to: "/service-providers",
        icon: <FaIcons.FaPeopleCarry />,
        allowed: "user-list",
      },
    ],
    allowed: "user-list",
  },
  {
    title: "System Settings",
    to: "system_settings",
    icon: <FaIcons.FaCogs />,
    subNav: [
      {
        title: "GRMS Sync",
        to: "/settings/grms",
        allowed: "report-evoucher-liquidation",
      },
      {
        title: "Settings Group",
        to: "/settings/settings-groups",
        allowed: "settings-grms-project-add",
      },
    ],
    allowed: "settings-grms-project-add",
  },
];
