import React, { useState, useEffect } from "react";
import { Alert, Box, Breadcrumbs, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "leaflet/dist/leaflet.css";
import { useGetFarmsQuery } from "../../../features/farms/farmsApiSlice";
import Spinner from "../../global/Spinner";
import { useTranslation } from "react-i18next";
import PolygonContainer from "./includes/PolygonContainer";
import PinsContainer from "./includes/PinsContainer";
import Overlaps from "./includes/Overlaps";
import Header from "../../Header";
import { Link } from "react-router-dom";
import { LinkColor } from "../../../theme";

const Farms = () => {
  const { t } = useTranslation();
  const [farms, setFarms] = useState([]);

  const [value, setValue] = useState("polygons");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, isLoading, isSuccess } = useGetFarmsQuery();

  useEffect(() => {
    if (data?.data) setFarms(data?.data);
  }, [data, isLoading, isSuccess]);

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("plots.index.title")}
        subtitle={t("plots.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Farms")}
        </Typography>
      </Breadcrumbs>

      {farms?.length < 1 ? (
        <Alert severity="info">{t("plots.index.no_plots_data")}</Alert>
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("plots.tab.polygons")} value="polygons" />
              <Tab label={t("plots.tab.pins")} value="pins" />
              <Tab label={t("plots.tab.overlaps")} value="overlaps" />
            </TabList>
          </Box>
          <TabPanel value="polygons">
            <PolygonContainer farms={farms} />
          </TabPanel>
          <TabPanel value="pins">
            <PinsContainer farms={farms} />
          </TabPanel>
          <TabPanel value="overlaps">
            <Overlaps />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );

  return content;
};

export default Farms;
