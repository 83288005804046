import { apiSlice } from "../../api/apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["payments"],
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: () => ({
        url: "/payment/list",
        method: "GET",
      }),
      keepUnusedDataFor: 2,
      providesTags: ["payments"],
    }),
    exportPaymentList: builder.mutation({
      query: (id) => ({
        url: `/payment/download/${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    importPaymentList: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/payment/import/${id}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["payments"],
    }),
    createPayment: builder.mutation({
      query: (payload) => ({
        url: "/payment/create",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["payments"],
    }),
    getSinglePaymentList: builder.query({
      query: (id) => ({
        url: `/payment/single/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["payments"],
    }),
    getPaymentTransactionsList: builder.query({
      query: ({ id, page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();
        return {
          url: `/payment/list/transactions/${id}/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 2,
    }),

    getPaymentFields: builder.query({
      query: () => ({
        url: "/payment/fields",
        method: "GET",
      }),
      keepUnusedDataFor: 2,
    }),

    getPayment: builder.query({
      query: (id) => ({
        url: `/payment/single/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useCreatePaymentMutation,
  useExportPaymentListMutation,
  useImportPaymentListMutation,
  useGetSinglePaymentListQuery,
  useGetPaymentTransactionsListQuery,

  useGetPaymentFieldsQuery,
  useGetPaymentQuery,
} = paymentsApiSlice;
