import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import {
  useExportPaymentListMutation,
  useGetSinglePaymentListQuery,
  useGetPaymentTransactionsListQuery,
  useImportPaymentListMutation,
} from "../../../../features/payments/paymentsApiSlice";
import Spinner from "../../../global/Spinner";
import {
  Box,
  Typography,
  Alert,
  Tab,
  Breadcrumbs,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from "../../../Header";
import { selectCurrentToken } from "../../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import Toast from "../../../global/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyledDataGrid from "../../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../../theme";

const ViewCashReportDetails = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [downloadingExcel, setDownloadExcel] = useState(false);
  const [importExcel, setImportExcel] = useState(false);

  // Pagination
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);
  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  // Get payment transaction list
  const {
    data: transactionData,
    isSuccess: transactionSuccess,
    isLoading: transactionLoading,
    isFetching: transactionFetching,
  } = useGetPaymentTransactionsListQuery({
    id,
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (transactionData?.data) setTransactions(transactionData?.data);
    if (transactionData?.meta)
      setPagination({
        currentPage: transactionData?.meta?.current_page,
        lastPage: transactionData?.meta?.last_page,
        perPage: transactionData?.meta?.per_page,
        total: transactionData?.meta?.total,
      });
  }, [transactionData, pagination.currentPage, pageSize]);

  // Get selected payment

  const { data, isLoading } = useGetSinglePaymentListQuery(id);

  useEffect(() => {
    if (data?.payment) setSelectedPayment(data?.payment);
  }, [data]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1);
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [value, setValue] = useState("transactions");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [exportData] = useExportPaymentListMutation();

  const handleExport = async () => {
    setDownloadExcel(true);
    try {
      const blob = await exportData(id).unwrap();

      const url = window.URL.createObjectURL(new Blob([blob]));

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${selectedPayment?.title}.xlsx`);

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      Toast("File downloaded successfully", "success");
      setDownloadExcel(false);
    } catch (err) {
      Toast(err?.data?.message || "Error downloading file", "error");
      setDownloadExcel(false);
    }
  };

  const [importPaymentList] = useImportPaymentListMutation();
  const fileInputRef = useRef(null);

  const handleImport = () => {
    fileInputRef.current.click();
  };
  // Handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setImportExcel(true);

      const formData = new FormData();
      formData.append("payment_report", file);

      try {
        const response = await importPaymentList({
          id,
          payload: formData,
        }).unwrap();
        Toast(response?.message, "success");
      } catch (err) {
        Toast(err?.data?.message || "Error importing file", "error");
      } finally {
        setImportExcel(false);
      }
    }
  };

  const transactionColumns = [
    {
      field: "transaction_code",
      headerName: t("payment.view.transaction_code"),
      flex: 1,
    },
    { field: "amount", headerName: t("payment.view.amount"), flex: 1 },
    {
      field: "beneficiary",
      headerName: t("payment.view.beneficiary"),
      valueGetter: (params) => params.row?.beneficiary.full_name,
      flex: 1,
    },
    {
      field: "package",
      headerName: t("payment.view.package"),
      valueGetter: (params) => params.row?.package.title,
      flex: 1,
    },
    {
      field: "intervention",
      headerName: t("payment.view.intervention"),
      valueGetter: (params) => params.row?.intervention.title,
      flex: 1,
    },
    { field: "status", headerName: t("payment.view.status"), flex: 1 },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("payment.view.title")}
        subtitle={t("payment.view.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="../cash-report">
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Payments")}
          </Typography>
        </Link>

        <Typography color="text.primary" variant="body2">
          {t("payment.view.view_payments")}
        </Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
          maxWidth: "500px",
        }}
      >
        <Paper variant="outlined">
          <Card sx={{ minWidth: 475 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {selectedPayment?.title}
              </Typography>
              <hr />
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.form.label.name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("cash.shared.table.intervention_name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.intervention?.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("cash.shared.table.project_name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.intervention?.project?.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("cash.shared.table.package_name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.package?.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("cash.shared.table.amount")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.amount}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.table.status")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPayment?.status}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", gap: "20px" }}>
        <button className="btn btn-primary " onClick={handleExport}>
          {downloadingExcel
            ? t("global.buttons.exporting_excel")
            : t("global.buttons.export_excel")}
        </button>
        <button className="btn btn-primary " onClick={handleImport}>
          {importExcel
            ? t("global.buttons.importing_excel")
            : t("global.buttons.import_excel")}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                label={t("payment.view.transactions")}
                value="transactions"
              />
            </TabList>
          </Box>
          <TabPanel value="transactions">
            <Box className="data_grid_box_container_within_tabs">
              {transactions?.length < 1 ? (
                <Alert severity="info">
                  {t("payment.view.no_transactions_in_payment")}
                </Alert>
              ) : (
                <StyledDataGrid
                  rows={transactions}
                  columns={transactionColumns}
                  pagination
                  paginationMode="server"
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  onFilterModelChange={handleFilterModelChange}
                  loading={transactionFetching}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return content;
};

export default ViewCashReportDetails;
