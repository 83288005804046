import { React, useState, useEffect } from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useTranslation } from "react-i18next";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import { useNavigate, Link } from "react-router-dom";

import { LinkColor } from "../../../../theme";
import Header from "../../../Header";
import Spinner from "../../../global/Spinner";
import StyledDataGrid from "../../../global/StyledDataGrid";

import { useGetPaymentsQuery } from "../../../../features/payments/paymentsApiSlice";

const CashReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading } = useGetPaymentsQuery();
  const [payments, setPayments] = useState([]);

  //   Pagination
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );

  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [filterModel, setFilterModel] = useState([]);

  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };

  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  useEffect(() => {
    setPage(1);
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };
  //  End of Pagination

  //   Get payments
  useEffect(() => {
    if (data?.data) setPayments(data?.data);
  });

  const handleView = (cash) => {
    cash = JSON.parse(cash);
    navigate(`view/${cash.id}`);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      renderCell: (params) => {
        const getPayment = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleView(getPayment())}
                size="small"
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("beneficiaries.table.fullname"),
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "intervention",
      headerName: t("intervention.shared.intervention"),
      valueGetter: (params) => params.row?.intervention.title,
      flex: 1,
    },
    {
      field: "package",
      headerName: t("packages.shared.package"),
      valueGetter: (params) => params.row?.package.title,
      flex: 1,
    },
    {
      field: "fields",
      headerName: t("payment.index.fields"),
      valueGetter: (params) => JSON.stringify(params.row?.fields),
      flex: 1,
    },
  ];

  const content = false ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("payment.index.title")}
        subtitle={t("payment.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>

        <Typography color="text.primary" variant="body2">
          {t("payment.index.subtitle")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="create" underline="none">
          <button className="btn btn-primary" variant="contained" size="small">
            {t("payment.index.create_payment")}
          </button>
        </Link>
      </Box>
      <Box className="data_grid_box_container">
        {payments?.length < 1 ? (
          <Alert severity="info">{t("payment.index.no_payment_data")}</Alert>
        ) : (
          <StyledDataGrid
            rows={payments}
            columns={columns}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
    </Box>
  );
  return content;
};

export default CashReport;
