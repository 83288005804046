import { apiSlice } from "../../api/apiSlice";

export const nonBiometricDeuplicationApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Duplicates"],
  endpoints: (builder) => ({
    checkDuplicates: builder.mutation({
      query: (payload) => ({
        url: "/deduplication/non-biometrics/check/duplicates",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Duplicates", "BeneficiariesStatus"],
    }),
    getDuplicates: builder.query({
      query: ({ page, pageSize }) => {
        const queryString = new URLSearchParams({
          page: page,
          pageSize: pageSize,
        }).toString();
        return {
          url: `deduplication/non-biometrics/fetch/duplicates/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Duplicates"],
      keepUnusedDataFor: 2,
    }),

    markDuplicate: builder.mutation({
      query: (payload) => ({
        url: "/deduplication/non-biometrics/mark/duplicates",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Duplicates", "BeneficiariesStatus"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    restoreDuplicate: builder.mutation({
      query: (payload) => ({
        url: `/deduplication/restore`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Duplicates", "BeneficiariesStatus"],
    }),
    // markBiometricDuplicate: builder.mutation({
    //   query: (payload) => ({
    //     url: "/deduplication/biometric/block",
    //     method: "POST",
    //     body: { ...payload },
    //   }),
    // }),
  }),
});

export const {
  useCheckDuplicatesMutation,
  useGetDuplicatesQuery,
  useMarkDuplicateMutation,
  useRestoreDuplicateMutation,
  // useMarkBiometricDuplicateMutation,
} = nonBiometricDeuplicationApiSlice;
