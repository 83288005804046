import React, { useEffect, useState } from "react";
import Spinner from "../../../global/Spinner";
import Toast from "../../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import { useCreatePaymentMutation } from "../../../../features/payments/paymentsApiSlice";
import { useGetInterventionsQuery } from "../../../../features/interventions/interventionsApiSlice";
import { useGetPackagesQuery } from "../../../../features/packages/packagesApiSlice";
import { useGetProjectsQuery } from "../../../../features/projects/projectsApiSlice";
import Header from "../../../Header";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../../theme";

const CreateCashReport = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [interventions, setInterventions] = useState([]);
  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [interventionId, setInterventionId] = useState("");
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);

  // Fetch Interventions Data
  const {
    data: iData,
    isSuccess: fetchingInterventionSuccess,
    isLoading: fetchingInterventions,
  } = useGetInterventionsQuery({
    page: 1,
    pageSize: 100,
  });

  // Fetch Packages Data
  const {
    data: pData,
    isSuccess: fetchingPackagesSuccess,
    isLoading: fetchingPackages,
  } = useGetPackagesQuery({
    page: 1,
    pageSize: 100,
  });

  // Update Interventions on Successful Fetch
  useEffect(() => {
    if (fetchingInterventionSuccess && iData?.data) {
      setInterventions(iData.data);
    }
  }, [fetchingInterventionSuccess, iData]);

  // Update Filtered Interventions on Project ID Change
  useEffect(() => {
    if (projectId) {
      filterInterventionsByProject();
    } else {
      setFilteredInterventions([]);
    }
  }, [projectId, interventions]);

  // Update Packages on Successful Fetch
  useEffect(() => {
    if (fetchingPackagesSuccess && pData?.data) {
      setPackages(pData.data);
    }
  }, [fetchingPackagesSuccess, pData]);

  // Update Filtered Packages on Intervention ID Change
  useEffect(() => {
    if (interventionId) {
      filterPackagesByIntervention();
    } else {
      setFilteredPackages([]);
    }
  }, [interventionId, packages]);

  // Filter Interventions by Project ID
  const filterInterventionsByProject = () => {
    setFilteredInterventions(
      interventions.filter(
        (intervention) =>
          intervention.project_id === projectId &&
          (intervention.cva_type === "CASH" ||
            intervention.cva_type === "CASH-PLUS")
      )
    );
  };

  // Filter Packages by Intervention ID
  const filterPackagesByIntervention = () => {
    setFilteredPackages(
      packages.filter(
        (packageData) => packageData.intervention.id === interventionId
      )
    );
  };

  // Handle Project ID Change
  const handleProjectId = (event) => {
    const newProjectId = event.target.value;
    setProjectId(newProjectId);
  };

  // Handle Intervention ID Change
  const handleInterventionId = (event) => {
    const newInterventionId = event.target.value;
    setInterventionId(newInterventionId);
  };

  const {
    data: projectData,
    isSuccess: fetchingProjectsSuccess,
    isLoading: fetchingProjects,
  } = useGetProjectsQuery({
    page: 1,
    pageSize: 100,
  });

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (projectData?.data) setProjects(projectData.data);
  }, [fetchingProjectsSuccess, projectData]);

  const [createPayment] = useCreatePaymentMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
    };

    try {
      const response = await createPayment(payload).unwrap();

      if (response.status === 200) {
        setIsSubmitting(false);
        navigate("/reports/cash-report");
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const initialValues = {
    title: "",
    project_id: "",
    intervention_id: "",
    package_id: "",
  };

  const paymentSchema = yup.object().shape({
    title: yup.string().required(t("payment.create.missing_title")),
    intervention_id: yup
      .string()
      .required(t("payment.create.missing_intervention")),
    package_id: yup.string().required(t("payment.create.missing_package")),
    project_id: yup.string().required(t("payment.create.missing_project")),
  });

  const content = fetchingProjects ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("payment.create.title")}
        subtitle={t("payment.create.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Payments")}
        </Typography>
        <Typography color="text.primary" variant="body2">
          {t("payment.create.create_payment")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={paymentSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("payment.create.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />
              {fetchingProjects && fetchingProjectsSuccess ? (
                <p>Loading Projects...</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("payment.create.choose_project")}
                  name="project_id"
                  value={values.project_id}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    handleProjectId(event);
                  }}
                  error={!!touched.project_id && !!errors.project_id}
                  helperText={touched.project_id && errors.project_id}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {projectId != null &&
                projectId !== "" &&
                (fetchingInterventions ? (
                  <p>Loading Interventions...</p>
                ) : (
                  <TextField
                    select
                    variant="filled"
                    label={t("payment.create.choose_intervention")}
                    name="intervention_id"
                    value={values.intervention_id}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      handleChange(event);
                      handleInterventionId(event);
                    }}
                    error={
                      !!touched.intervention_id && !!errors.intervention_id
                    }
                    helperText={
                      touched.intervention_id && errors.intervention_id
                    }
                  >
                    {filteredInterventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}

              {interventionId != null &&
                interventionId !== "" &&
                (fetchingPackages ? (
                  <p>Loading Packages...</p>
                ) : (
                  <TextField
                    select
                    variant="filled"
                    label={t("payment.create.choose_package")}
                    name="package_id"
                    value={values.package_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!touched.package_id && !!errors.package_id}
                    helperText={touched.package_id && errors.package_id}
                  >
                    {filteredPackages.map((packageData) => (
                      <MenuItem key={packageData.id} value={packageData.id}>
                        {packageData.title}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}
              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("payment.create.amount")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
              /> */}
            </Box>
            {/* <hr />
            <Box
              display="flex"
              justifyContent="space-around"
              alignContent="space-between"
              alignItems="center"
              justifyItems="center"
              m="20px"
            >
              <Box>
                <Typography variant="h4" color="secondary">
                  {t("payment.create.beneficiary_fields")}
                </Typography>
                <hr />

                {beneficiaryFields.map((field, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>{field}</span>
                        <input
                          value={field}
                          type="checkbox"
                          onChange={handleBeneficiaryCheck}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box>
                <Typography variant="h4" color="secondary">
                  {t("payment.create.package_fields")}
                </Typography>
                <hr />

                {packageFields.map((field, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>{field}</span>
                        <input
                          value={field}
                          type="checkbox"
                          onChange={handlePackageCheck}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box> */}
            {/* <hr /> */}
            <Box display="flex" justifyContent="end" mt="20px">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Submitting ..."
                  : t("payment.create.create_payment")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default CreateCashReport;
