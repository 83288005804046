import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../features/settings/languageSlice";
import { useDispatch } from "react-redux";
import { FormControl, Select, MenuItem } from "@mui/material";
import { GB, FR, PT } from "country-flag-icons/react/3x2";

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const flagIconStyle = {
    height: "10px",
    width: "20px",
  };
  const languages = [
    {
      code: "en",
      name: t("language.english"),
      flag: <GB style={flagIconStyle} />,
    },
    {
      code: "fr",
      name: t("language.french"),
      flag: <FR style={flagIconStyle} />,
    },
    {
      code: "pt",
      name: t("language.portuguese"),
      flag: <PT style={flagIconStyle} />,
    },
  ];

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("ideaLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      dispatch(setLanguage(storedLanguage));
    }
  }, [dispatch, i18n]);

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    localStorage.setItem("ideaLanguage", language);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  };

  return (
    <FormControl size="small">
      <Select onChange={handleLanguageChange} value={i18n.language}>
        {languages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            <span>
              {language.flag} {language.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
