import { apiSlice } from "../../api/apiSlice";

export const farmsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Farms", "PlotOverlaps"],
  endpoints: (builder) => ({
    getFarms: builder.query({
      query: ({ page = 1, pageSize = 10, ...apiFilters } = {}) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/beneficiaries/plots?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Farms"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getOverlaps: builder.query({
      query: () => ({
        url: "/deduplication/polygons/fetch/overlaps",
        method: "GET",
      }),
      providesTags: ["PlotOverlaps"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useGetFarmsQuery, useGetOverlapsQuery } = farmsApiSlice;
