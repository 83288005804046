import { React, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
} from "@mui/material";
import Pagination from "../../shared/Pagination";

import { MdExpandMore } from "react-icons/md";
import RestoreIcon from "@mui/icons-material/Restore";
import StyledDataGrid from "../../../global/StyledDataGrid";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useGetDuplicatesQuery } from "../../../../features/biometric_deduplication/nonBiometricDeuplicationApiSlice";
import { useGetBeneficiaryByStatusQuery } from "../../../../features/beneficiaries/beneficiariesApiSlice";
import Spinner from "../../../global/Spinner";
import ConfirmRestoreDialog from "./ConfirmRestoreDialog";

const DuplicatesDataTable = ({ onPossibleDuplicate, onBeneficiarySelect }) => {
  const { t } = useTranslation();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [value, setValue] = useState("1");
  const [possibleExpanded, setPossibleExpanded] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [markedDuplicates, setMarkedDuplicates] = useState([]);
  const [openRestore, setOpenRestore] = useState(false);
  const [restoreBeneficiary, setRestoreBeneficiary] = useState("");

  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [possiblePagination, setPossiblePagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const handlePossibleChange = (panel, beneficiary) => (event, isExpanded) => {
    setPossibleExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      onBeneficiarySelect(beneficiary);
      onPossibleDuplicate(undefined);
    } else {
      onBeneficiarySelect(undefined);
      onPossibleDuplicate(undefined);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // List duplicates
  const { data, isLoading, isSuccess, isFetching } = useGetDuplicatesQuery({
    page,
    pageSize,
  });

  useEffect(() => {
    if (data?.data) {
      setDuplicates(data?.data);

      setPossiblePagination({
        currentPage: data?.current_page,
        lastPage: data?.last_page,
        perPage: data?.per_page,
        total: data?.total,
      });
    }
  }, [
    data,
    isLoading,
    isFetching,
    isSuccess,
    pageSize,
    pagination.currentPage,
  ]);

  // List marked duplicated
  const checkPayload = {
    page,
    pageSize,
    status: "DUPLICATE",
  };

  const {
    data: isMarkedDuplicates,
    isLoading: isMarkedDuplicatesLoading,
    isSuccess: isMarkedDuplicatesSuccess,
  } = useGetBeneficiaryByStatusQuery(checkPayload);

  useEffect(() => {
    if (isMarkedDuplicates?.data) setMarkedDuplicates(isMarkedDuplicates?.data);
    if (isMarkedDuplicates?.meta)
      setPagination({
        currentPage: isMarkedDuplicates?.meta?.current_page,
        lastPage: isMarkedDuplicates?.meta?.last_page,
        perPage: isMarkedDuplicates?.meta?.per_page,
        total: isMarkedDuplicates?.meta?.total,
      });
  }, [
    isMarkedDuplicates,
    isMarkedDuplicatesLoading,
    isMarkedDuplicatesSuccess,
    page,
    pageSize,
    pagination.currentPage,
  ]);

  const handleDeduplicateView = (duplicate) => {
    onPossibleDuplicate(duplicate);
  };

  const handleOpenRestore = (beneficiary) => {
    setRestoreBeneficiary(beneficiary);
    setRestoreBeneficiary(JSON.parse(beneficiary));
    setOpenRestore(true);
  };

  const handleCloseRestore = () => {
    setOpenRestore(false);
  };

  const handlePageChange = (params) => {
    setPage(params);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const getBeneficiary = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleOpenRestore(getBeneficiary())}
                size="small"
              >
                {t("nonbiometrics.table.restore_button")} <RestoreIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.id_number"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 200,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      minWidth: 200,
    },
    {
      field: "code",
      headerName: t("project.form.label.code"),
      valueGetter: (params) => params?.row?.intervention[0]?.project?.code,
      minWidth: 200,
    },
  ];

  return (
    <Box>
      {duplicates.length === 0 ? (
        <Alert severity="info" sx={{ marginY: "20px" }}>
          <AlertTitle>{t("biometrics.index.biometric_alert_title")}</AlertTitle>
          {t("biometrics.index.biometric_alert_subtitle")}
        </Alert>
      ) : null}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            aria-label={t("sidebar.Beneficiaries")}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label={t("biometrics.index.possible_duplicates")}
              value="1"
              onClick={() => onPossibleDuplicate(undefined)}
            />
            <Tab
              label={t("biometrics.index.marked_duplicates")}
              value="2"
              onClick={() => onPossibleDuplicate(undefined)}
            />
          </TabList>
        </Box>
        {/* Possible duplicates panel */}
        {isLoading ? (
          <Spinner />
        ) : (
          <TabPanel value="1">
            {/* Panel header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "79%",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{ width: "20%", textAlign: "start", paddingLeft: "24px" }}
              >
                ID
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                Full Name
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                ID Number
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                Phone Number
              </Typography>
            </Box>

            {/* Panel content */}
            {isFetching ? (
              <Spinner />
            ) : (
              <div>
                {duplicates?.map((duplicate) => (
                  <Accordion
                    key={duplicate?.beneficiary?.id}
                    expanded={possibleExpanded === duplicate?.beneficiary?.id}
                    onChange={handlePossibleChange(
                      duplicate?.beneficiary?.id,
                      duplicate?.beneficiary
                    )}
                  >
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ borderBottom: "1px solid #CFCFCF" }}
                    >
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "start",
                          paddingLeft: "35px",
                        }}
                      >
                        {duplicate?.beneficiary?.id}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "center",
                        }}
                      >
                        {duplicate?.beneficiary?.full_name}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "center",
                        }}
                      >
                        {duplicate?.beneficiary?.id_number}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "center",
                        }}
                      >
                        {duplicate?.beneficiary?.telephone}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      {duplicate?.duplicates?.map((dup) => (
                        <Box
                          key={dup?.duplicate?.id}
                          sx={{
                            display: "flex",
                            border: "1px solid #CFCFCF",
                            borderRadius: "10px",
                            padding: "10px",
                            marginY: "10px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "80%",
                              justifyContent: "space-around",
                              alignItems: "center",
                              // background: "red",
                            }}
                          >
                            <Typography
                              sx={{
                                width: "20%",
                                flexShrink: 0,
                                textAlign: "start",
                              }}
                            >
                              {dup?.duplicate?.id}
                            </Typography>
                            <Typography
                              sx={{
                                width: "17%",
                                flexShrink: 0,
                                textAlign: "center",
                                color: "#980000",
                              }}
                            >
                              {dup?.duplicate?.full_name}
                            </Typography>
                            <Typography
                              sx={{
                                width: "20%",
                                flexShrink: 0,
                                textAlign: "center",
                                color: "#980000",
                              }}
                            >
                              {dup?.duplicate?.id_number}
                            </Typography>
                            <Typography
                              sx={{
                                width: "20%",
                                flexShrink: 0,
                                textAlign: "center",
                                color: "#980000",
                              }}
                            >
                              {dup?.duplicate?.telephone}
                            </Typography>
                          </Box>
                          <button
                            className="btn"
                            onClick={() =>
                              handleDeduplicateView(dup?.duplicate)
                            }
                          >
                            View
                          </button>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )}

            {/* table pagination goes here */}
            <Pagination
              totalItems={possiblePagination.total}
              itemsPerPage={possiblePagination.perPage}
              onPageChange={handlePageChange}
            />
          </TabPanel>
        )}

        {/* Marked as duplicates panel */}
        <TabPanel value="2">
          <Box className="data_grid_box_container">
            <StyledDataGrid
              rows={markedDuplicates}
              columns={columns}
              pagination
              pageSize={pagination.perPage}
              rowCount={pagination.total}
              onPageChange={handlePageChange}
              loading={isMarkedDuplicatesLoading}
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={default_rows_per_page_options}
            />
          </Box>
          {openRestore && (
            <ConfirmRestoreDialog
              open={openRestore}
              onClose={handleCloseRestore}
              beneficiary={restoreBeneficiary}
            />
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default DuplicatesDataTable;
