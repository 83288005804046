import React, { useEffect, useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonGroup,
} from "@mui/material";
import StyledDataGrid from "../../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { Cancel, Check } from "@mui/icons-material";
import FloatingPlotTable from "./FloatingPlotTable";
import ZoomToPolygon from "./ZoomToPolygon";

const MapComponent = ({
  data,
  latitude = 25.0888596,
  longitude = 55.1482862,
}) => {
  const { t } = useTranslation();
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [open, setOpen] = useState(false);
  const [farmToZoom, setFarmToZoom] = useState(null);
  const [mainPlots, setMainPlots] = useState(null);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const map_center_lat =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LAT || latitude;
  const map_center_lon =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LON || longitude;
  const map_zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL || 15;

  useEffect(() => {
    if (data) {
      const plots = data.map((item) => item.plot);
      setMainPlots(plots);
    }
  }, [data, farmToZoom]);

  const handlePolygonClick = (plot) => {
    setSelectedPlot(plot);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFarmToView = (plotId) => {
    const farm = mainPlots.find((farm) => farm?.id === plotId);
    const plot = data.find((farm) => farm?.plot.id === plotId);

    setSelectedPlot(plot);
    setFarmToZoom(farm);
  };

  const handleOverlappingPlot = (overlappingPlotId, action) => {
    //console.log(overlappingPlotId);
    //console.log(action);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      renderCell: (params) => {
        const getPlot = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id };
          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };
        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleOverlappingPlot(getPlot(), "overlapping")}
              >
                <Cancel />
              </Button>
              <Button
                color="secondary"
                onClick={() => handleOverlappingPlot(getPlot(), "okay")}
              >
                <Check />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    { field: "plot_name", headerName: t("global.form.label.name"), flex: 1 },
    { field: "score", headerName: t("plots.table.score"), flex: 0.5 },
  ];

  const rows = selectedPlot
    ? selectedPlot.overlaps.map((overlap) => ({
        id: overlap?.overlap?.id,
        score: overlap?.score,
        plot_name: overlap?.overlap?.plot_name,
      }))
    : [];

  return (
    <Box position="relative">
      <MapContainer
        center={[map_center_lat, map_center_lon]}
        zoom={map_zoom}
        style={{ height: "80vh" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {farmToZoom && <ZoomToPolygon farm={farmToZoom} />}
        {data.map((plot) => (
          <Polygon
            key={plot.plot.id}
            pathOptions={{
              color:
                selectedPlot && selectedPlot.plot.id === plot.plot.id
                  ? "blue"
                  : "red",
              fillColor:
                selectedPlot && selectedPlot.plot.id === plot.plot.id
                  ? "blue"
                  : "red",
            }}
            positions={plot.plot.plot_polygon_geometry.coordinates}
            eventHandlers={{
              click: () => handlePolygonClick(plot),
            }}
          >
            {/* {selectedPlot && selectedPlot.plot.id === plot.plot.id && (
              <Popup onClose={() => setSelectedPlot(null)}>
                <Box sx={{ height: "35vh", width: "300px", padding: "10px" }}>
                  {selectedPlot && (
                    <StyledDataGrid rows={rows} columns={columns} rowsPerPageOptions={default_rows_per_page_options} />
                  )}
                </Box>
              </Popup>
            )} */}
          </Polygon>
        ))}
        {selectedPlot &&
          selectedPlot.overlaps.map((overlap) => (
            <Polygon
              key={overlap.id}
              pathOptions={{
                color: "red",
                fillColor: "red",
              }}
              positions={overlap.overlap.plot_polygon_geometry.coordinates}
            />
          ))}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t("plots.table.overlapping_plots")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ height: 400, width: "100%" }}>
                <StyledDataGrid
                  columns={columns}
                  rows={rows}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              autoFocus
              size="small"
            >
              {t("global.page.close_button")}
            </Button>
          </DialogActions>
        </Dialog>
      </MapContainer>
      {mainPlots && (
        <FloatingPlotTable farms={mainPlots} farmToView={handleFarmToView} />
      )}
    </Box>
  );
};

export default MapComponent;
