import { useState, useRef, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Visibility, Close } from "@mui/icons-material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Draggable from "react-draggable";
import StyledDataGrid from "../../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { useGetFarmsQuery } from "../../../../features/farms/farmsApiSlice";

const FloatingPlotTable = ({ farmToView }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const cardRef = useRef(null);
  const [position, setPosition] = useState({ x: 20, y: 20 });

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);
  const [farms, setFarms] = useState([]);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);

  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } = useGetFarmsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setFarms(data?.data);

    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isLoading, isSuccess, pagination.currentPage, page, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleToggleCard = () => {
    setIsOpen(!isOpen);
  };

  const handleDrag = (event, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  const handleViewPlot = (plot) => {
    const parsedPlot = JSON.parse(plot);
    farmToView(parsedPlot.id, farms);
  };

  const columns = [
    {
      field: "action",
      headerName: t("plots.table.view"),
      flex: 0.5,
      renderCell: (params) => {
        const getPlot = () => {
          const fields = params.api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id };
          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });
          return JSON.stringify(thisRow, null, 4);
        };
        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button color="warning" onClick={() => handleViewPlot(getPlot())}>
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    { field: "plot_name", headerName: t("plots.table.plot_name"), flex: 1 },
  ];

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <IconButton
          onClick={handleToggleCard}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          {isOpen ? (
            <Close color="error" />
          ) : (
            <Visibility sx={{ color: "black" }} />
          )}
        </IconButton>
      </Box>
      {isOpen && (
        <Draggable nodeRef={cardRef} onDrag={handleDrag} position={position}>
          <Box
            ref={cardRef}
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              zIndex: 999,
              width: "25vw",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={handleToggleCard}>
                <Close sx={{ color: "red" }} />
              </IconButton>
            </Box>
            <Card>
              <CardContent>
                <Box style={{ height: "60vh", width: "100%" }}>
                  <StyledDataGrid
                    rows={farms}
                    columns={columns}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    rowCount={pagination.total}
                    loading={isFetching}
                    onFilterModelChange={handleFilterModelChange}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Draggable>
      )}
    </>
  );
};

export default FloatingPlotTable;
